import { api, responseErrorCheck } from 'stylewhere/api'

export class MutualAuth {
  static endpoint = 'api/v1/urely/mutualAuth'

  static async challenge(apdu: string, challenge: string) {
    return api
      .get<any>(`${this.endpoint}/challenge`, { apduRequest: apdu, challengeCode: challenge })
      .then((res) => responseErrorCheck(res))
  }

  static async validate(apdu: string, connectionId: string) {
    return api
      .get<any>(`${this.endpoint}/validate`, { apduRequest: apdu, connectionId })
      .then((res) => responseErrorCheck(res))
  }
}
