import { datadogRum } from '@datadog/browser-rum'
import Base from 'core/pages/Encoding/BrowserPrint/BrowserPrintEncode'
import { TmrTag } from 'stylewhere/api'
import { EncodingExtensions } from 'stylewhere/extensions'
import { AppStore, RfidReader, Router } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import {
  askUserConfirmation,
  getAskUserInputEveryTime,
  isExpectOnlyMandatoryIdentifiers,
  showToastError,
  sleep,
} from 'stylewhere/shared/utils'

export default class BrowserPrintEncode extends Base {
  isDecodeNfc = false
  isWrongTag = false

  startAntenna = async () => {
    if (!RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.start(undefined, () => {}, undefined)
    }
  }

  startReader = async () => {
    try {
      this.isWrongTag = false
      await RfidReader.stopWsCustomCommands()
      await RfidReader.start(undefined, () => {}, undefined)
    } catch (error) {
      if (!AppStore.getEmulation()) {
        showToastError(
          (error as any).message ?? __(T.error.rfid_reader_initialization),
          __(T.error.error),
          this.isModal
        )
      }
    }
  }

  attachAutomaticReaderStop = () => {
    if (this.operation.autostopAntennaTimeout > 0) {
      this.clearTimerReader()
      this.timerReader = setTimeout(async () => {
        this.setState({ associationStatus: 'ERROR' })
        await this.stopAntenna()
        if (this.checkIfForceCreate('yes')) {
          this.isEncoding = true
          this.setState(
            { forceAssociation: true, pin: '', encodingCreateCounter: this.state.encodingCreateCounter + 1 },
            this.create
          )
        } else if (this.checkIfForceCreate('withUserConfirmation')) {
          const { encodingValidationResponse } = this.state
          const errors =
            encodingValidationResponse && encodingValidationResponse.errors ? encodingValidationResponse.errors : []
          // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
          if (
            errors.length == 1 &&
            errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
            isExpectOnlyMandatoryIdentifiers(this.operation)
          ) {
            this.call_force_encoding(false)
          }
          // else {
          //   const ok = await askUserConfirmation(
          //     __(T.misc.attention),
          //     this.getWithUserConfirmationMessage(errors, encodingValidationResponse)
          //   )
          //   if (ok) {
          //     this.call_force_encoding(false)
          //   } else {
          //     this.clear()
          //     this.isEncoding = false
          //   }
          // }
          // } else {
          // this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, () => this.create(true))
        }
      }, this.operation.autostopAntennaTimeout)
    }
  }

  onTagRead = async (tag: TmrTag) => {
    await sleep(Math.random() * 500)

    const { encodingValidation, encodingValidationResponse, targetTag, associationStatus } = this.state
    if (!encodingValidation || !encodingValidation.identifiers) return
    if (this.isWrongTag) {
      if (tag.epc) console.log('UHF discard by wrong tag', tag.epc)
      return
    }

    if (tag.epc) console.log('UHF used tag', tag.epc)

    //da verificare in base ai controlli sottostanti
    RfidReader.setAutomaticStop(false)
    RfidReader.stopTimer()

    //se leggo tag e ho nfc con decode in corso scarto tag e lo rimuovo dal reader
    if (this.isDecodeNfc) {
      this.removeTagReadFromReader([tag])
      return
    }

    if (
      this.state.encodingValidation?.identifiers.some(
        (id) => id.identifierType === 'UHF_TAG' && id._status === 'ERROR' && id._error === __(T.error.wrong_tag)
      )
    ) {
      console.log('Ignoring tag', tag.uid ?? tag.epc, 'due to wrong tag error')
      this.setState({ processing: false })
      return
    }

    if (
      tag.uid &&
      !encodingValidationResponse?.configuration?.details.some((conf) => conf.identifierType === 'NFC_TAG')
    ) {
      this.setState({ processing: false })
      return
    }

    this.isEncoding = true
    if (tag.uid) console.log('Entering encoding for NFC ', tag.uid)
    else if (tag.epc) console.log('Entering encoding for UHF ', tag.epc)

    if (tag.epc && tag.epc !== targetTag) {
      if (
        encodingValidation.identifiers.some(
          (id) => id.identifierType === 'UHF_TAG' && id.code === targetTag && id._status === 'CONFIRMED'
        )
      ) {
        // the correct tag was already read. We'll have an "extra tag" error
        console.log('UHF discard already confirmed', tag.epc)
        return
      } else {
        this.isWrongTag = true
        console.log('UHF wrong tag', tag.epc)
        const newEncodingValidation = { ...encodingValidation }
        newEncodingValidation.identifiers.forEach((element) => {
          if (element.identifierType === 'UHF_TAG') {
            element._status = 'ERROR'
            element._error = __(T.error.wrong_tag)
          } else if (element.identifierType === 'NFC_TAG' && !element._status) {
            element._status = 'ERROR'
            element._error = __(T.imbustatrice.dynamic_tag_missing, { role: element.role })
          }
        })
        this.setState({ encodingValidation: newEncodingValidation, processing: false, associationStatus: 'ERROR' })
        showToastError(__(T.error.wrong_tag), __(T.error.error), this.isModal)
        this.isEncoding = false
        await this.stopAntenna()
        this.clearTimerReader()
        this.datadogSession?.errors?.push({ error: 'wrongUhfTagRead', details: { expected: targetTag, read: tag.epc } })
        datadogRum.addAction('err_wrongUhfTagRead', this.datadogSession)
        return
      }
    }
    try {
      this.clearTimerReader()
      this.clearTimer()
      const nfcRequiresMutualAuth =
        this.operation.attributes['nfc-requires-mutual-authentication'] &&
        this.operation.attributes['nfc-requires-mutual-authentication'] === 'true' &&
        encodingValidationResponse?.configuration?.details.some((conf) => conf.identifierType === 'NFC_TAG')
      if (tag.uid && nfcRequiresMutualAuth) {
        this.isDecodeNfc = true
        this.setState({ processing: 'nfcDecryption' })
      }

      await EncodingExtensions.onTagRead(encodingValidation!, tag, this.operation)
      const nfcError = encodingValidation.identifiers.some(
        (id) => id.identifierType === 'NFC_TAG' && id._status === 'ERROR'
      )
      if (tag.uid && nfcRequiresMutualAuth) {
        console.log('NFC decoded as ', tag.uid)
        this.setState(
          {
            processing: false,
            associationStatus: nfcError ? 'ERROR' : associationStatus,
          },
          () => {
            if (nfcError) {
              this.isEncoding = false
              this.isDecodeNfc = false
              this.stopAntenna()
              this.clearTimer()
              this.clearTimerReader()
            }
          }
        )
      }
      const nfcCollision = encodingValidation.identifiers.some(
        (id) => id.identifierType === 'NFC_TAG' && id._status === 'NFC_COLLISION'
      )
      this.setState({ encodingValidation }, () => {
        if (nfcCollision) {
          console.log('NFC collision detected')
          this.setState({ processing: false, associationStatus: 'ERROR' })
          this.isEncoding = false
          this.isDecodeNfc = false
          this.stopAntenna()
          this.clearTimer()
          this.clearTimerReader()
          this.datadogSession?.errors?.push({ error: 'nfcCollision' })
          datadogRum.addAction('err_nfcCollision', this.datadogSession)
        } else {
          if (this.datadogSession) {
            if (tag.uid) {
              this.datadogSession.nfcRead = {
                offsetMs: Date.now() - this.datadogSession.startTime.getTime(),
                nfcRead: tag.uid,
              }
              datadogRum.addAction('nfcRead', this.datadogSession)
            } else if (tag.epc) {
              this.datadogSession.uhfRead = {
                offsetMs: Date.now() - this.datadogSession.startTime.getTime(),
                uhfRead: tag.epc,
              }
              datadogRum.addAction('uhfRead', this.datadogSession)
            }
          }
          this.onTagReadTimer()
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ processing: false })
      this.isEncoding = false
      this.isDecodeNfc = false
    }
  }

  onTagReadValidate = async () => {
    try {
      this.isEncoding = true
      console.log('Validating encoding', this.state.encodingValidation)
      const res = await EncodingExtensions.validate(this.state.encodingValidation!)
      if (
        this.state.encodingValidation?.identifiers.some(
          (id) => id.identifierType === 'UHF_TAG' && id._status === 'ERROR' && id._error === __(T.error.wrong_tag)
        )
      ) {
        console.log('Ignoring tags due to wrong tag error')
        return
      }
      if (
        this.state.encodingValidation?.identifiers.some(
          (id) =>
            id.identifierType === 'NFC_TAG' &&
            id._status === 'ERROR' &&
            id._error === __(T.error.authentication_timeout)
        )
      ) {
        console.log('nfc authentication timeout')
        this.datadogSession?.errors?.push({
          error: 'nfcAuthTimeout',
          details: { nfcAuthTimeoutOffsetMs: Date.now() - this.datadogSession.startTime.getTime() },
        })
        datadogRum.addAction('err_nfcAuthTimeout', this.datadogSession)
      }
      if (
        res.encodingValidationResponse.configuration?.details.filter((conf) => conf.identifierType === 'NFC_TAG')
          .length === 1
      ) {
        if (res.encodingValidationResponse.item.identifiers.filter((id) => id.type === 'NFC_TAG').length > 1) {
          if (
            res.encodingValidationResponse.item.identifiers.some(
              (id) => id.type === 'NFC_TAG' && id.status === 'CONFIRMED'
            ) &&
            res.encodingValidationResponse.item.identifiers.some((id) => id.type === 'NFC_TAG' && id.status === 'ERROR')
          ) {
            res.encodingValidationResponse.item.identifiers = res.encodingValidationResponse.item.identifiers.filter(
              (id) => id.type !== 'NFC_TAG' || id.status !== 'ERROR'
            )
          }
        }
      }
      if (this.state.associationStatus === 'CONFIRMED') {
        this.isEncoding = false
        return
      }
      this.setState(
        {
          encodingValidationResponse: res.encodingValidationResponse,
          encodingValidation: res.encodingValidation,
        },
        this.onTagReadValidateResponse
      )
    } catch (error) {
      this.encodingError(error, 'Unknown error during encoding validation')
    } finally {
      this.isEncoding = false
    }
  }

  // callback response validate on flow product and order
  onTagReadValidateResponse = async () => {
    const { encodingValidationResponse, encodingCreateCounter, associationStatus } = this.state

    if (encodingValidationResponse) {
      if (encodingValidationResponse.operationToPerform === 'NONE') {
        await this.onValidationOperationNone()
      } else if (
        encodingValidationResponse.operationToPerform === 'UPDATE_ONLY_PRODUCT' ||
        encodingValidationResponse.operationToPerform === 'UPDATE_WAM'
      ) {
        const ok = await askUserConfirmation(__(T.misc.attention), __(T.misc.update_only_product))
        if (ok) {
          this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
        } else {
          if (getAskUserInputEveryTime(this.operation)) {
            await this.stopAntenna()
          }
          // this.clear()
          this.setState({ associationStatus: 'ERROR' })
          this.isEncoding = false
        }
      } else if (encodingValidationResponse.operationToPerform === 'FAIL') {
        // this.setState({ associationStatus: 'ERROR' })
        // this.feedbackSoundKo()
        const immediateError = encodingValidationResponse.errors.some((e) => e.errorCode === 'MISSING_SYSTEM_TAG')
        this.isEncoding = false
        this.setState({ processing: false, associationStatus: immediateError ? 'ERROR' : associationStatus }, () => {
          if (!immediateError) {
            this.attachAutomaticReaderStop()
          } else {
            this.stopAntenna()
          }
        })
      } else if (
        encodingValidationResponse.operationToPerform === 'FORCE_ITEM_CREATION' ||
        encodingValidationResponse.operationToPerform === 'REWRITE_CONFIGURATION' ||
        encodingValidationResponse.operationToPerform === 'UPDATE_ITEM'
      ) {
        this.clearTimerReader()
        this.stopAntenna()
        this.checkEncodingForceOption()
      } else {
        this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
      }
    } else {
      this.feedbackSoundKo()
      this.setState({ processing: false })
      this.isEncoding = false
    }
  }

  clearReads = async (addtags = false, noask = true) => {
    const { encodingValidation, resetFormCounter, targetTag } = this.state
    this.clearTimer()
    RfidReader.clear()
    if (addtags && encodingValidation) {
      RfidReader.tags = encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .map((idf) => ({ epc: idf.code, tid: idf.code } as any))
    }

    if (getAskUserInputEveryTime(this.operation) && !noask) {
      RfidReader.setAutomaticStop(false)
      await this.stopAntenna()
      this.setState(
        {
          formSchemaData: { product: { code: '' }, wam: '' },
          associationStatus: 'TO_BE_READ',
          tagsRead: [],
          resetFormCounter: resetFormCounter + 1,
          pin: '',
          forceAssociation: false,
          encodingCreateCounter: 0,
          numWriteAttempt: 0,
          targetTag: targetTag ?? '',
          serial: '',
          processing: false,
        },
        this.resetValidateResponse
      )
    } else {
      RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
      if ((this.state.formSchemaData.product.code as string).trim().length === 0) return
      const res = await EncodingExtensions.getItemConfiguration(this.operation, this.state.formSchemaData)
      // keep serial
      const serial = encodingValidation?.identifiers.find((idf) => idf.identifierType === 'SIMPLE_ITEM_IDENTIFIER')
      if (serial) serial._status = 'SKIPPED'

      this.setState(
        {
          encodingValidation: {
            ...res.encodingValidation,
            identifiers: [
              ...res.encodingValidation.identifiers
                .filter((idf) => idf.identifierType !== 'SIMPLE_ITEM_IDENTIFIER')
                .concat(serial ? [serial] : []),
            ],
          },
          encodingValidationResponse: res.encodingValidationResponse,
          counters: res.counters,
          encodingCreateResponse: undefined,
          associationStatus: 'TO_BE_READ',
          tagsRead: [],
          pin: '',
          forceAssociation: false,
          encodingCreateCounter: 0,
          numWriteAttempt: 0,
          targetTag: targetTag ?? '',
          serial: '',
          processing: false,
        },
        async () => {
          this.isWrongTag = false
          await RfidReader.stopWsCustomCommands()
          if (!addtags) {
            await this.startAntenna()
          }
        }
      )
    }
  }

  resetAfterCreate = async () => {
    this.onClear(true)
  }

  _changeTab = async () => {
    localStorage.setItem('originTemplatePath', this.operation.templatePath)
    RfidReader.clear()
    await this.stopAntenna()
    this.timer = setTimeout(() => {
      Router.navigate(
        `/encoding/:opCode/verify`,
        {
          opCode: this.operation.code,
        },
        {
          state: {
            originTemplatePath: this.operation.templatePath,
          },
        }
      )
    }, this.operation.options.antennaTurnWaitingInterval ?? 1000)
  }

  getDisableRetry() {
    return false
  }

  render() {
    return super.render()
  }
}
